import { Checkmark, ChevronDown, ChevronUp } from 'ck-assets';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';
import {
  ErrorMessages,
  FormLabelWithCount,
  getRequiredOption,
} from 'ck-components';

import { Listbox } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';

interface Props<T extends FieldValues> {
  parentForm: UseFormReturn<T>;
  optionsData: PathValue<T, Path<T>>[];
  useOtherValues?: PathValue<T, Path<T>>[];
  name: Path<T>;
  disabled?: boolean | undefined;
  horizontal?: boolean | undefined;
  label?: string;
  description?: string;
  errorsAlwaysTakeUpSpace?: boolean;
  emptyStateLabel?: string;
  multiple?: boolean;
  optional?: boolean;
  isShorter?: boolean;
  onChange?: (val: any) => void;
  value?: any;
}

/**
 * SingleSelectDropdown component that allows selecting a single option from a dropdown menu
 */
// TODO: Break out base component that can be shared between MultiSelectDropdown and SingleSelectDropdown
/**
 *
 * @param   optionsData, the lables and values of each option
 * @param useOtherValyes, optional, will replace the value of options (but not the labels)
 * @returns
 */
export const SingleSelectDropdown = <T extends FieldValues>({
  optionsData,
  name,
  parentForm,
  disabled,
  description,
  label,
  emptyStateLabel = label ? `Välj ${label.toLowerCase()}` : '',
  optional,
  errorsAlwaysTakeUpSpace = true,
  useOtherValues,
  isShorter,
  value,
  onChange,
}: Props<T>) => {
  const { control, formState, watch } = parentForm;
  const valueInForm = value ?? watch(name);
  const handleListboxChange = (selected: PathValue<T, Path<T>>) => {
    if (onChange) {
      onChange(selected);
    } else {
      if (useOtherValues) {
        parentForm.setValue(
          name,
          useOtherValues[optionsData.indexOf(selected)]
        );
      } else {
        parentForm.setValue(name, selected);
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={value ? undefined : { required: getRequiredOption(optional) }}
      render={() => (
        <div>
          <div className={clsx('relative w-full', !valueInForm && '-mb-1')}>
            <Listbox
              value={valueInForm}
              disabled={disabled}
              onChange={handleListboxChange}
              name={name}
            >
              {({ open }) => (
                <>
                  {label && (
                    <FormLabelWithCount
                      label={label}
                      description={description}
                      parentForm={parentForm}
                      name={name}
                    />
                  )}
                  <Listbox.Button
                    id={name}
                    className={clsx(
                      'mb-0 flex h-14 w-full items-center justify-between rounded border border-gray-300 bg-white px-4 text-left focus:outline-sky-500',
                      disabled &&
                        'cursor-not-allowed bg-neutral-50 text-gray-400 placeholder:text-gray-400 hover:border-gray-300 focus:border-gray-300'
                    )}
                  >
                    {(useOtherValues
                      ? optionsData[useOtherValues.indexOf(valueInForm)]
                      : valueInForm) || (
                      <span className='text-gray-400'>{emptyStateLabel}</span>
                    )}
                    <ReactSVG
                      src={open ? ChevronUp : ChevronDown}
                      className='w-6'
                    />
                  </Listbox.Button>
                  <Listbox.Options
                    className={clsx(
                      'absolute z-50 flex w-full flex-col overflow-y-auto rounded border-2 border-gray-300',
                      !valueInForm && '-mt-[1px]'
                    )}
                  >
                    <div
                      className={clsx(
                        'relative max-h-[300px]',
                        isShorter && 'max-h-[200px]'
                      )}
                    >
                      {optionsData.map((option, idx) => (
                        <Listbox.Option
                          as='div'
                          className='flex h-14 w-full cursor-pointer items-center border-b border-gray-300 bg-white px-4'
                          key={`${option}-${idx}`}
                          value={option}
                        >
                          {({ selected }) => (
                            <li className='flex w-full items-center justify-between'>
                              {option}
                              {selected && <ReactSVG src={Checkmark} />}
                            </li>
                          )}
                        </Listbox.Option>
                      ))}
                    </div>
                  </Listbox.Options>
                  <ErrorMessages
                    errors={formState.errors}
                    name={name}
                    takeUpSpace={errorsAlwaysTakeUpSpace}
                  />
                </>
              )}
            </Listbox>
          </div>
        </div>
      )}
    />
  );
};
