import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EventsParticipants } from './types';
import { GK } from 'ck-queries';
import { fetchWithErrorThrowing } from 'ck-utils';
import toast from 'react-hot-toast';

export const useEventsDownloadParticipants = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (event: EventsParticipants) =>
      toast.promise(
        postDownloadParticipants(event.eventId, event.title, event.startDate),
        {
          loading: 'Laddar...',
          success: 'Deltagarlistor exporterade',
          error: 'Kunde inte exportera deltagarlistor',
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: GK.events,
      });
    },
  });
};

async function postDownloadParticipants(
  eventId: string,
  title: string,
  startDate: string
) {
  return fetchWithErrorThrowing(`/events/${eventId}/participants/download`, {
    method: 'POST',
  })
    .then(async (response) => {
      const blob = await response.blob();
      return { blob };
    })
    .then(({ blob }) => {
      // Create blob URL without explicitly setting the download attribute
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}_${startDate}.csv`); // Set the filename
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);

      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    });
}
